import Vue from 'vue'
import Vuex from 'vuex'
import { GetLeftMenu } from "../request/api"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //菜单列表信息
    menuinfo: [],
    username: "",
    userphone:""
  },
  mutations: {
    //更新菜单列表信息
    setmenuinfo(state, info) {
      state.menuinfo = info
    },
    setusername(state, payload) {
      state.username = payload
    },
    setuserphone(state, payload) {
      state.userphone = payload
    }
  },
  actions: {
    // 解决刷新后菜单数据丢失

    async asyncsetmenuinfo({ commit }, payload) {
      commit("setusername", sessionStorage.getItem("user_name"))
      let res = await GetLeftMenu()

      let info = res.data.info.filter((item) => {
        return item.id > 2
      })
      commit("setmenuinfo",info)


    }

  },
  modules: {
  }
})
