import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "about" */ '../views/setting.vue')
  },
  {
    path: '/garbagechild1',
    name: 'test3',
    component: () => import(/* webpackChunkName: "about" */ '../views/test3.vue')
  },
  {
    path: '/api/home/getdeviceidlist',
    name: 'test1',
    component: () => import('../views/test1.vue')
  },
  {
    path: '/garbagechild2',
    name: 'test8',
    component: () => import('../views/test8.vue')
  },
  ,
  {
    path: '/test2',
    name: 'test2',
    component: () => import('../views/test2.vue')
  },
  {
    path: '/test15',
    name: 'test15',
    component: () => import('../views/test15.vue')
  },
  {
    path: '/api/vihicle/getvihiclelist',
    name: 'getvihiclelist',
    component: () => import('../views/getvihiclelist.vue')
  },
  {
    path: '/api/staff/stafflist',
    name: 'stafflist',
    component: () => import('../views/test17.vue')
  },
  {
    path: '/api/staff/staffpunchlog',
    name: 'staffpunchlog',
    component: () => import('../views/test6.vue')
  },
  {
    path: '/CheckTrack',
    name: 'CheckTrack',
    component: () => import('../views/CheckTrack.vue')
  },
  {
    path: '/PathPlanning',
    name: 'PathPlanning',
    component: () => import('../views/PathPlanning.vue')
  },
  {
    path: '/api/vihicle/fencelist',
    name: 'fencelist',
    component: () => import('../views/fencelist.vue')
  },
  {
    path: '/archivesInfo',
    name: 'archivesInfo',
    component: () => import('../views/archivesInfo.vue')
  },
  {
    path: '/garbagechild3',
    name: 'garbagechild3',
    component: () => import('../views/garbagechild3.vue')
  },
  {
    path: '/img',
    name: 'img',
    component: () => import('../views/img.vue')
  },

  {
    path: '/streetList',
    name: 'streetList',
    component: () => import('../views/StreetList.vue')
  },
  {
    path: '/log',
    name: 'log',
    component: () => import('../views/Communicationlog.vue')
  },
  {
    path: '/cardReport',
    name: 'cardReport',
    component: () => import('../views/CardReport.vue')
  },


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path != "/login" && !sessionStorage.getItem("token")) {
    next({ path: "/login" })
  } else {
    next()
  }
})

export default router
