<template>
  <div id="app">
    <div id="menu_switch" @mouseover="onmouseover"></div>
    <div
      id="menu"
      :style="{ left: menuswitch ? '0px' : '-250px' }"
      @mouseleave="onmouseleave"
    >
      <div class="administrators">
        <span>管理员:{{ $store.state.username }}</span>
      </div>
      <el-col :span="12">
        <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#101838"
          text-color="#fff"
          active-text-color="#ffd04b"
          :unique-opened="true"
          :router="true"
        >
          <el-menu-item index="/setting" class="el-submenu__title"
            >设置</el-menu-item
          >
          <el-menu-item index="/index" class="el-submenu__title"
            >智慧环卫平台</el-menu-item
          >
          <el-menu-item index="/streetList" class="el-submenu__title"
            >街道管理</el-menu-item
          >
          <el-menu-item index="/log" class="el-submenu__title"
            >通讯日志</el-menu-item
          >

          <el-submenu
            :index="'/' + item.name"
            v-for="item in $store.state.menuinfo"
            :key="item.name"
          >
            <template slot="title">
              <span>{{ item.remark }}</span>
            </template>
            <el-menu-item
              :index="'/' + every.name"
              v-for="every in item.second"
              :key="every.name"
              >{{ every.remark }}</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </el-col>
    </div>
    <router-view />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      menuswitch: false,
      Menudata: [],
    };
  },
  mounted() {
    //获取菜单数据
    if (sessionStorage.getItem("token")) {
      this.asyncsetmenuinfo();
    }
  },
  computed: {},

  methods: {
    ...mapActions({ asyncsetmenuinfo: "asyncsetmenuinfo" }),
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    //鼠标移入菜单显示
    onmouseover() {
      if (this.$route.path != "/login") {
        this.menuswitch = true;
      }
    },
    //鼠标移出菜单隐藏
    onmouseleave() {
      this.menuswitch = false;
    },
  },
};
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;

  #menu_switch {
    height: 100vh;
    width: 20px;
    position: absolute;
    z-index: 10;
  }

  #menu {
    height: 100vh;
    width: 250px;
    position: absolute;
    background-color: #101838;
    z-index: 10;
    transition: 0.6s;
    overflow: hidden;

    .el-col {
      width: 250px;
      height: 100%;
    }

    .el-menu {
      border: none;
    }
  }

  .administrators {
    height: 56px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
  }

  .el-menu-item-group__title {
    display: none;
  }

  .el-submenu__title {
    font-size: 18px !important;
    border-bottom: rgba(128, 128, 128, 0.4) solid 1px;
  }

  .el-menu-item {
    font-size: 16px;
    border-bottom: rgba(128, 128, 128, 0.4) solid 1px;
  }
}
</style>
