//统一管理接口
import request from "@/request/request.js"

//登录
export const LoginAPI = (params) => request.post("api/home/zhhwAdminLogin", params)

//获取左侧菜单栏信息
export const GetLeftMenu = () => request.get("api/home/getLeftMenu")

//首页大屏地埋桶数据接口
export const GetHomePageMain = () => request.get("api/home/getHomePageMain")

//首页大屏其他数据接口
export const getHomePageOther = () => request.get("api/home/getHomePageOther")

// 首页大屏 监控链接
export const getLiveSource = (params) => request.post("api/home/getLiveSource", params)

//垃圾分类>设备管理 获取投放点列表
export const GetPointList = (params) => request.get("api/home/getPointList", { params: params })

//垃圾分类>设备管理 添加\编辑投放点
export const addEditPoint = (params) => request.post("api/home/addEditPoint", params)

//垃圾分类>设备管理 删除投放点
export const DeletePoint = (params) => request.post("api/home/deletePoint", params)

//垃圾分类>设备管理  获取投放点地埋桶列表
export const getPointBarrelList = (params) => request.get("api/home/getPointBarrelList", { params })

//垃圾分类>设备管理 获取地埋桶设备ID列表
export const getDeviceIdList = (params) => request.get("api/home/getDeviceIdList", { params })

//垃圾分类>设备管理 给桶位绑定/取消绑定传感器设备ID
export const bindDeviceForBarrel = (params) => request.post("api/home/bindDeviceForBarrel", params)

//获取街道列表
export const GetStreetList = (params) => request.get("api/home/getStreetList", { params })
//获取筛选街道列表
export const getGrantStreetList = (params) => request.get("api/home/getGrantStreetList", { params })

//设置 获取管理员列表 
export const ClientUserList = (params) => request.get("api/home/clientUserList", { params })

//设置 添加管理员
export const addAdmin = (params) => request.post("api/home/addAdmin", params)
//设置 编辑管理员
export const editAdmin = (params) => request.post("api/home/editAdmin", params)
//设置 删除管理员
export const adminUserDelete = (params) => request.post("api/home/adminUserDelete", params)

//设置 管理员修改密码
export const revisePassword = (params) => request.post("api/home/revisePassword", params)

//设置 角色组编辑接口
export const roleRuleEditPage = (params) => request.get("api/home/roleRuleEditPage", { params: params })

//角色组列表
export const roleList = () => request.get("api/home/roleList")

//设置 角色规则编辑提交 
export const rulesInfoPost = (params) => request.post("api/home/rulesInfoPost", params)

//设置 分配街道 
export const streetDistributeToAdmin = (params) => request.post("api/home/streetDistributeToAdmin", params)

//车辆管理>车辆列表
export const getVihicleList = (params) => request.get("api/vihicle/getVihicleList", { params })

//人员管理>人员列表
export const staffList = (params) => request.get("api/Staff/staffList", { params })

//人员管理>添加、编辑、删除人员
export const operateStaff = (params) => request.post("api/Staff/operateStaff", params)

//人员管理>人员详情编辑
export const operateStaffDetail = (params) => request.post("api/Staff/operateStaffDetail", params)

//人员管理 > 考勤记录 
export const staffPunchLog = (params) => request.get("api/staff/staffPunchLog", { params })

//围栏列表
export const fenceList = (params) => request.get("api/vihicle/fenceList", { params })

//新增围栏
export const operateFence = (params) => request.post("api/vihicle/operateFence", params)

//删除围栏
export const deleteFence = (params) => request.post("api/vihicle/deleteFence", params)

//编辑围栏管理车辆
export const fenceVihicle = (params) => request.post("api/vihicle/fenceVihicle", params)

//传感器设备ID信息编辑/删除
export const barrelDeviceOperate = (params) => request.post("api/home/barrelDeviceOperate", params)

//车辆档案信息登记
export const archivesSignIn = (params) => request.post("api/vihicle/archivesSignIn", params)

//车辆档案信息 / 维修保养详情
export const archivesInfo = (params) => request.post("api/vihicle/archivesInfo", params)

//添加，编辑，删除车辆
export const operateVihicle = (params) => request.post("api/vihicle/operateVihicle", params)

//车辆维修保养信息登记
export const maintenanceSignIn = (params) => request.post("api/vihicle/maintenanceSignIn", params)

//事件列表
export const getEventListWeb = (params) => request.get("api/garbage/getEventListWeb", { params })

//投放点设备故障登记/恢复
export const pointWorkBreakSign = (params) => request.post("api/garbage/pointWorkBreakSign", params)

//添加街道
export const addStreet = (params) => request.post("api/home/addStreet", params)

//删除街道
export const streetDelete = (params) => request.post("api/home/streetDelete", params)
//编辑
export const streetEdit = (params) => request.post("api/home/streetEdit", params)

//获取省份列表
export const getProvince = (params) => request.get("api/home/getProvince", { params })

//获取市列表
export const getCity = (params) => request.get("api/home/getCity", { params })

//获取区县列表
export const getDistrict = (params) => request.get("api/home/getDistrict", { params })

//代理转发
export const proxyRequest = (params) => request.post("api/index/proxyRequest", params)
// 通讯日志
export const deviceLog = (params) => request.get("api/garbage/deviceLog", { params })
//同步车辆GPS设备ID
export const asyncDeviceId = (params) => request.post("api/index/asyncDeviceId", params)

// 通讯日志
export const getYunAccessToken = () => request.get("api/home/getYunAccessToken")


  // 点位颜色开关
export const setManualControl = (params) => request.post("api/home/setManualControl", params)

// 点位颜色
export const changePointColor = (params) => request.post("api/home/changePointColor", params)
//获取车辆月度报表
export const monthReport = (params) => request.get("api/vihicle/monthReport", { params })
// 批量修改车辆月度报表
export const vihicleBucket = (params) => request.post("api/vihicle/vihicleBucket", params)

//获取车辆vihicleDayStatistics
export const vihicleDayStatistics = (params) => request.get("api/vihicle/vihicleDayStatistics", { params })


//获取车辆
export const getCarLocationInfo = (params) => request.get("api/index/getCarLocationInfo", { params })


//获取车辆v
export const getVihicleSimulateData = (params) => request.get("api/vihicle/getVihicleSimulateData", { params })

// 添加车辆模拟数据
export const addVihicleSimulateData = (params) => request.get("/api/Vihicle/addVihicleSimulateData", { params })
