import axios from "axios"
import router from '@/router'
import { Message } from 'element-ui'
//封装接口地址
const instance = axios.create({
    // baseURL: "https://zhhw.fenfeneco.com/",
    baseURL: "http://zhhw1.fenfeneco.com/",
})


// 请求拦截器
instance.interceptors.request.use(function (config) {
    //统一携带token
    let token = sessionStorage.getItem("token")
    if (token) {
        config.headers['token'] = token
    }

    return config;
}, function (error) {
    // 请求错误
    return Promise.reject(error);
});

// 响应拦截器
instance.interceptors.response.use(function (response) {

    if (response.data.code === 401) {
        router.push("/login")
        Message.error('登录过期，请重新登录')
        return
    }
    return response.data;

}, function (error) {
    // 响应错误
    return Promise.reject(error);
});



//导出

export default instance;
