import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./css/global.css"
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import scroll from 'vue-seamless-scroll'
Vue.prototype.$echarts = echarts;
Vue.use(scroll)
Vue.use(echarts)
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
